<template>
    <div id="notes-list">
      <b-button v-if="user.hasPermission('crm:contact.note.create')" variant="primary" size="sm" class="mb-3" v-b-modal.modal-note>
        <BIconPlus font-scale="1.2"/> Add Note
      </b-button>

      <NoteModal v-if="user.hasPermission('crm:contact.note.create')" ref="modalNoteEl" @deleted="refreshNotesList()" @saved="refreshNotesList()" :contact="contact"/>
      <NotesList v-if="!notes.isLoading" @note-selected="openNote" :notes="notes" style="max-height: 300px; overflow: auto;"/>
      <b-spinner v-else label="Loading..." small style="margin: 20px;"></b-spinner>
      <b-pagination v-if="!notes.isLoading && notes.pagination.totalPages > 1" @change="getNotes" v-model="notes.pagination.currentPageNo" :total-rows="notes.total" :per-page="notes.pagination.perPage" size="sm" align="fill"></b-pagination>
    </div>
</template>

<script>
import {BIconPlus} from 'bootstrap-vue';
import NoteModal from './Note.Modal';
import NotesList from './Notes.List';
export default {
    props: ['contact'],
    components: {
      BIconPlus,
      NoteModal,NotesList
    },
    data(){
        return {
          notes: {
            isLoading: true,
            records: [],
            total: 0,
            pagination: {
              currentPageNo: 1
            }
          }
        };
    },
    methods: {
      refreshNotesList(){
        this.getNotes(1);
      },

      openNote(data){
        this.$refs['modalNoteEl'].openModal(data);
      },

      getNotes(pageNo=1){
        this.notes.isLoading = true;
        this.$api.get(`crm/contact/${this.contact.id}/notes`,{
          params: {
            perPage: 5,
            page: pageNo,
          }
        }).then(res => {
          let {records, pagination, total} = res.data;
          this.notes.isLoading = false;
          this.notes.records = records;
          this.notes.pagination = pagination;
          this.notes.total = total;
        }).catch((e) => {console.log(e,'cannot get notes')});
      }
    },

    mounted(){
      this.getNotes(1);
    },

    computed: {
        user(){
            return this.$store.getters['User/Profile/getProfile'];
        }
    }
};
</script>
