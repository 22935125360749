<template>
    <div id="note">
      <b-modal ref="modalNote" size="lg" id="modal-note" :title="title" @show="onModalOpen()" @hide="onModalClose()" @ok="saveNote()" ok-only>
        <b-form-textarea
          id="note-input"
          v-model="noteMessage"
          placeholder="write a note..."
          rows="6"
          no-resize
        ></b-form-textarea>
        <template #modal-footer="{ ok }">
          <b-button size="sm" variant="success" @click="ok()">
            <span v-if="note">Update</span>
            <span v-else>Save</span>
          </b-button>
          <b-button v-if="note" size="sm" variant="danger" @click="removeNote()">
            Delete
          </b-button>
        </template>
      </b-modal>
    </div>
</template>

<script>
export default {
    props: ['contact'],
    components: {

    },
    data(){
        return {
          note: null,
          noteMessage: null,
          title: "New Note"
        };
    },

    methods: {
      onModalOpen(){
        this.noteMessage = null;
        if(this.note){
          this.title = "Update Note";
          this.noteMessage = this.note.content;
        }
      },
      onModalClose(){
        this.noteMessage = null;
        this.note = null;
      },
      removeNote(){
        this.$api.delete(`crm/contact/${this.contact.id}/note/${this.note.id}`).then(() => {
          this.noteMessage = null;
          this.note = null;
          this.$emit('deleted');
          this.$refs['modalNote'].hide();
        }).catch(() => {console.log('cannot delete note')});
      },
      saveNote(){
        let callable = null;
        if(this.note){
          callable = this.$api.put(`crm/contact/${this.contact.id}/note/${this.note.id}`,{
            data: {
              content: this.noteMessage
            }
          })
        }
        else{
          callable = this.$api.post(`crm/contact/${this.contact.id}/note`,{
            data: {
              content: this.noteMessage
            }
          })
        }

        callable.then(() => {
          this.noteMessage = null;
          this.note = null;
          this.$emit('saved');
        }).catch(() => {console.log('cannot save note')});
      },

      openModal(note){
        this.note = note;
        this.$refs['modalNote'].show();
      }
    },

    mounted(){

    }
};
</script>
